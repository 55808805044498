// If you don't want to use TypeScript you can delete this file!
import * as React from "react"

import Layout from "../components/layout"
import { TempContainer } from "../components/TempContainer"
import { Heading, Text } from "../gazebo"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const AboutPage = () => (
	<Layout>
		<SEO title="About" />
		<TempContainer>
			<Heading mb={4} fontSize={700} style={{ textAlign: "center" }}>
				About Me
			</Heading>
			<div style={{ width: "400px", margin: "0 auto 4rem auto" }}>
				<StaticImage
					src="../images/becca-on-street.jpg"
					width={400}
					layout="constrained"
					quality={100}
					formats={["AUTO", "WEBP", "AVIF"]}
					alt="Becca on street"
					placeholder="blurred"
					style={{ width: "400px", margin: "0 auto" }}
				/>
			</div>
			<div style={{ width: "69ch", maxWidth: "90%", margin: "0 auto" }}>
				<Text mb={4}>
					Becca is a licensed social worker in the state of Massachusetts. Becca has worked in a variety of settings, including a
					grant-funded preschool program and a hospital and community-based behavioral health case management program.
				</Text>
				<Text mb={2}>
					Becca completed her Masters level internship at a community mental health agency, where she provided intensive in-home therapy and
					case management services to at-risk children and their families.
				</Text>
				<Text mb={2}>
					Becca has specific interests in medical social work, housing and other social determinants of health, working with children and
					families, and child welfare policy.
				</Text>
				<Text mb={2}>
					Becca will graduate from Boston University School of Social Work in July, 2021 with her MSW. Becca is open to work.
				</Text>
			</div>
		</TempContainer>
	</Layout>
)

export default AboutPage
